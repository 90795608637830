.default-info {
  padding: 40px 0;

  &__container {
    background: no-repeat center url(../imgs/bg-info.png);
    background-size: 100% 100%;
    padding: 50px 40px;
    max-width: 1200px;

    @include media-max(1235px) {
      padding: 50px 40px;
      background-size: calc(100% - 30px) 100%;
      background-position: top left 15px;
    }

    @include media-max(575px) {
      padding: 0 15px;
      background-image: none;
    }
  }

  .default-info__row {
    @include media-max(575px) {
      background: rgba(221, 242, 254, 1);
      background: -moz-linear-gradient(top, rgba(221, 242, 254, 1) 0%, rgba(240, 250, 255, 1) 100%);
      background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(221, 242, 254, 1)), color-stop(100%, rgba(240, 250, 255, 1)));
      background: -webkit-linear-gradient(top, rgba(221, 242, 254, 1) 0%, rgba(240, 250, 255, 1) 100%);
      background: -o-linear-gradient(top, rgba(221, 242, 254, 1) 0%, rgba(240, 250, 255, 1) 100%);
      background: -ms-linear-gradient(top, rgba(221, 242, 254, 1) 0%, rgba(240, 250, 255, 1) 100%);
      background: linear-gradient(to bottom, rgba(221, 242, 254, 1) 0%, rgba(240, 250, 255, 1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ddf2fe', endColorstr='#f0faff', GradientType=0);
      border-radius: 25px 45px 45px 25px;
      padding: 15px;
    }
  }

  &__table {
    background: no-repeat center url(../imgs/bg-info-tab.png);
    background-size: 100% 100%;
    padding: 40px 30px 30px;

    @include media-max(991px) {
      padding-left: 15px;
      padding-right: 15px;
    }

    @include media-max(767px) {
      margin-bottom: 25px;
    }
  }
}