.how-make {

  &__single {
    padding-top: 30px;
    padding-botom: 30px;
  }


  &__image {

    &--ico {
      display: inline-block;
      position: relative;
    }
  }

  &__number {
    width: 31px;
    height: 31px;
    background-color: #034798;
    border-radius: 50%;
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    line-height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 5px;
    left: -40px;
  }
}