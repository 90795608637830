.we-love-children {
  position: relative;

  &__text {
    position: relative;
    background: no-repeat center url(../imgs/bg-we-love-children.png);
    background-size: 100% 100%;
    padding: 30px 150px;
    margin-top: 100px;

    @include media-max(991px) {
      padding: 40px 80px;
    }

    @include media-max(575px) {
      padding: 40px;
    }

    &::before {
      content: '';
      background: no-repeat center / cover url(../imgs/humana-baby-family.png);
      width: 108px;
      height: 155px;
      position: absolute;
      top: -120px;
      left: 220px;

      @include media-max(991px) {
        left: 100px;
      }

      @include media-max(575px) {
        left: 70px;
      }
    }
  }
}