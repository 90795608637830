.slider {
  &__menu {
    margin: 20px 0;
    text-align: center;
    padding: 0;
    list-style: none;

    li {
      display: inline-block;
      vertical-align: middle;
      margin: 5px;
      padding: 6px 38px 9px;
      background: no-repeat center url(../imgs/bg-btn.png);
      background-size: 100% 100%;
      color: #fff;
      font-size: 1.9rem;
      font-weight: 700;
      line-height: 2.5rem;
      cursor: pointer;

      @include media-max(575px) {
        padding-left: 30px;
        padding-right: 30px;
      }

      a {
        color: #fff;
      }

      &:hover,
      &.current {
        opacity: .8;
      }
    }
  }

  &__container {
    display: flex;
    align-items: center;
    margin-top: 30px;
  }

  &__arrow {
    width: 45px;
    height: 30px;
    background: no-repeat center / contain;
    opacity: .8;
    cursor: pointer;

    @include media-max(991px) {
      margin-top: 10vw;
    }

    @include media-max(767px) {
      width: 30px;
    }

    &:hover {
      opacity: 1;
    }

    &.hide {
      opacity: .4;
      cursor: default;
    }

    &--left {
      background-image: url(../imgs/arrow-left.png);
    }

    &--right {
      background-image: url(../imgs/arrow-right.png);
    }
  }

  &__box {
    width: calc(100% - 90px);
    padding: 0 40px;
    position: relative;

    @include media-max(991px) {
      margin-bottom: 30px;
    }

    @include media-max(767px) {
      width: calc(100% - 60px);
      padding: 0 15px;
    }
  }

  &__bg {
    text-align: center;
    margin-bottom: -50px;

    @include media-max(1199px){
      margin-bottom: -20px;

      img {
        max-width: 76%;
      }
    }

    @include media-max(767px) {

      img {
        width: calc(100% + 60px);
        max-width: inherit;
        margin-left: -30px;
      }
    }
  }

  &__items {
    @include media-min(992px) {
      text-align: center;
      position: absolute;
      top: 0;
      left: 40px;
      right: 40px;
      bottom: 0;
    }
  }

  &__item:not(.current) {
    display: none;
  }

  &__item {

    @include media-min(992px) {
      &__text {
        display: none !important;
      }
    }

    @include media-max(991px) {
      &.special-slide--1 {
        margin-top: 0;
      }

      img {
        display: none !important;
      }

      &__text {
        display: block;
      }
    }

  }

  img {
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
}