.support {
  &__container {
    max-width: 820px;
    background: no-repeat center url(../imgs/bg-support.png);
    background-size: 100% 100%;
    padding: 40px 85px 25px 55px;
    margin-top: 70px;
    margin-bottom: 20px;
    position: relative;

    @include media-max(991px) {
      background-size: calc(100% - 30px) 100%;
      background-position: top left 15px;
    }

    @include media-max(575px) {
      padding-left: 45px;
      padding-right: 45px;
      background-image: none;

      &::after {
        content: '';
        width: calc(100% - 30px);
        height: 100%;
        display: block;
        position: absolute;
        border: 2px solid #85bd3b;
        border-radius: 25px 50px;
        top: 0;
        left: 15px;
        right: 15px;
        z-index: -1;
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      background: no-repeat url(../imgs/bg-support-sun.png) center / cover;
      width: 82px;
      height: 76px;

      @include media-max(991px) {
        width: 40px;
        height: 40px;
        top: 10px;
        right: 10px;
      }

      @include media-max(575px) {
        top: -10px;
      }
    }
  }

  &__image {
    margin-top: -70px;
    margin-bottom: -80px;

    @include media-max(575px) {
      width: 80px;
      margin-bottom: 15px;
    }
  }

  &__col {
    padding-left: 15px;
    padding-right: 15px;

    &--image {
      margin-right: 54px;
      width: 160px;

      @include media-max(767px) {
        margin-right: 20px;
      }

      @include media-max(575px) {
        width: 100%;
        margin-right: 0;
        text-align: center;
      }
    }

    &--text {
      width: calc(100% - 214px);

      @include media-max(767px) {
        width: calc(100% - 180px);
      }

      @include media-max(575px) {
        width: 100%;
      }
    }
  }

  &__headline {
    padding-right: 80px;

    @include media-max(575px) {
      padding-right: 0;
    }
  }
}