.page {
  position: relative;
  min-height: 100vh;
  overflow: hidden;
  
  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: no-repeat url(../imgs/bg-header.png) center top / cover;
    background-size: 100% auto;
    z-index: 1;
  }

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: no-repeat url(../imgs/bg-footer.png) center bottom / cover;
    background-size: 100% auto;
    z-index: 0;
  }
}

.content {
  position: relative;
  z-index: 2;
  overflow: hidden;
  min-height: 100vh;
}