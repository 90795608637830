.btn {
  color: #fff;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 2.4rem;
  border-radius: 5px;
  background-color: #034798;
  display: inline-block;
  padding: 17px 20px;
  transition: .3s;
  -webkit-box-shadow: inset 0px 0px 0px 3px rgba(3,71,152,1);
  -moz-box-shadow: inset 0px 0px 0px 3px rgba(3,71,152,1);
  box-shadow: inset 0px 0px 0px 3px rgba(3,71,152,1);

  &:hover {
    background-color: transparent;
    color: #034798;
    text-decoration: none;
  }

  &--2 {
    color: #034798;
    font-size: 1.6rem;
    font-weight: 300;
    line-height: 2.2rem;
    -webkit-box-shadow: inset 0px 0px 0px 1px rgba(3,71,152,1);
    -moz-box-shadow: inset 0px 0px 0px 1px rgba(3,71,152,1);
    box-shadow: inset 0px 0px 0px 1px rgba(3,71,152,1);
    background-color: transparent;
    padding: 7px 16px;

    &:hover {
      background-color: #034798;
      color: #FFF;
    }
  }
}