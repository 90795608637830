.special-mt--1 {
  margin-top: 180px;

  @include media-max(991px) {
    margin-top: 30px;
  }
}

.special-mt--2 {
  margin-top: 120px;

  @include media-max(1800px) {
    margin-top: 30px;
  }

  @include media-max(767px) {
    margin-top: 0px;
  }
}

.special-mt--3 {
  margin-top: 20px;
}

.special-mt--4 {
  margin-top: 80px;

  @include media-max(991px) {
    margin-top: 30px;
  }
}

.special-mt--5 {
  margin-top: 40px;
}

.special-mt--6 {
  margin-top: 50px;
}

.special-mt--7 {
  margin-top: 70px;

  @include media-max(767px) {
    margin-top: 20px;
  }
}

@include media-max(767px) {
  .special-sm-mb--1 {
    margin-bottom: 30px;
  }
}

@include media-max(575px) {
  .special-xs-mt--1 {
    margin-top: 30px;
  }
}

.special-c--1 {
  color: #087dc2;
}

.special-c--2 {
  color: #a9d15b;
}

.special-c--3 {
  color: #00a393;
}

.special-arrow {
  display: inline-block;
  position: relative;
  margin-bottom: 20px;

  &::after {
    content: '';
    width: 127px;
    height: 45px;
    position: absolute;
    bottom: -8px;
    right: -27px;
    background: no-repeat center url(../imgs/wrapped-arrow-up.png);
  }
}

.special-ico--warning {
  display: inline-block;
  vertical-align: top;
  width: 40px;
  height: 36px;
  background: no-repeat center url(../imgs/ico-warning.png);
  margin-top: -4px;
  margin-right: 8px;

  @include media-max(575px) {
    margin: 0 auto 15px;
    display: block;
  }
}

.special-slide--1 {
  margin-top: 89px;
}

.flex-vertical-center {
  display: flex;
  align-items: center;
}

.line--1 {
  max-width: 607px;
  width: 100%;
  height: 5px;
  background: no-repeat url(../imgs/bg-line.png) center;
  background-size: 100% 100%;
  margin: 40px auto;

  @include media-max(640px) {
    background-size: calc(100% - 30px) 100%;
    background-position: top left 15px;
  }

  @include media-max(575px) {
    margin: 15px auto;
  }
}
