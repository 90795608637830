@mixin media-max($breakpoint) {
  @media (max-width: $breakpoint) { @content; }
}
@mixin media-min($breakpointMin) {
  @media (min-width: $breakpointMin) { @content; }
}

body, html {
  margin: 0;
  padding: 0;
  font-size: 10px;
  line-height: 10px;
  font-family: "VAGRoundedLTCom";
  font-weight: 400;
  color: #034798;

  @include media-max(767px) {
    font-size: 9px;
    line-height: 9px;
  }

}

body {
  line-height: inherit;
  min-width: 320px;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

.ttable {
  display: table;
  width: 100%;

  color: #034798;
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 2.6rem;
  margin-bottom: 15px;

  .btn {
    margin-bottom: 0;
  }
}

.ttr {
  display: table-row;

  &:first-child,
  &.th_sorted {
    font-weight: 700;
    background-color: #73ba40;
    color: #fff;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1.6rem;

    .ttd {
      border-top: 0;

      a {
        color: inherit;
      }
    }
  }

  &.tr_search .ttd {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.ttd {
  display: table-cell;
  vertical-align: middle;
  /* padding: 5px 10px; */
  border-top: 1px solid #73ba40;
  line-height: 1.42857;
  padding: 8px;
}


.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

@include media-max(767px) {
  .text-sm-left {
    text-align: left;
  }

  .text-sm-center {
    text-align: center;
  }

  .text-sm-right {
    text-align: right;
  }
}

@include media-max(575px) {
  .text-xs-left {
    text-align: left;
  }

  .text-xs-center {
    text-align: center;
  }

  .text-xs-right {
    text-align: right;
  }
}

.hide_box {
  display: none !important;
}

.headline {
  margin: 0 0 20px 0;
  font-weight: 700;

  &--1 {
    color: #034798;
    font-size: 3.6rem;
    font-weight: 700;
    line-height: 4rem;

    @include media-max(991px) {
      font-size: 2.8rem;
      line-height: 2.8rem;
    }
  }

  &--2 {
    color: #034798;
    font-size: 4.6rem;
    font-weight: 700;
    line-height: 4rem;

    @include media-max(991px) {
      font-size: 4.2rem;
      line-height: 4.2rem;
    }
  }

  &--3 {
    color: #034798;
    font-size: 3.6rem;
    font-weight: 700;
    line-height: 4.6rem;
  }

  &--4 {
    color: #034798;
    font-size: 2.4rem;
    font-weight: 700;
    line-height: 3.2rem;
  }

  &--title {
    width: 100%;
    max-width: 680px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}

.no-ma {
  margin: 0 !important;
}

.no-mt {
  margin-top: 0 !important;
}

.no-mb {
  margin-bottom: 0 !important;
}

.paragraph {
  margin: 0 0 15px 0;

  &--1 {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2.6rem;
  }

  &--2 {
    font-size: 2rem;
    font-weight: 400;
    line-height: 2.6rem;
  }

  &--3 {
    color: #999;
    font-size: 1.2rem;
    font-weight: 300;
    line-height: 2.2rem;
  }
}

.container--2 {
  max-width: 880px;
  width: 100%;
}

.row {
  margin-left: -15px;
  margin-right: -15px;
}

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12 {
  padding-left: 15px;
  padding-right: 15px;
}

section {
  padding: 20px 0;
}

.fw300 {
  font-weight: 300;
}

.fw400 {
  font-weight: 400;
}

.fw700 {
  font-weight: 700;
}